import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import CustomLink from 'components/shared/CustomLink'

const StyledUl = styled.ul`
  list-style: none;
  margin-left: -2.5rem;
`

const StyledLink = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.light};

  &:hover {
    color: ${(props) => props.theme.color.text.alt};
  }
`

const MenuItems = ({ items }) => {
  return (
    <>
      <StyledUl>
        {items.map((item, index) => (
          <li key={index}>
            <StyledLink to={item.uri}>{item.title}</StyledLink>
          </li>
        ))}
      </StyledUl>
    </>
  )
}

export default MenuItems
