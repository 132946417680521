import React from 'react'
import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundImageCSS = css`
  background-size: ${props =>
    props.styles && props.styles.backgroundSize ? props.styles.backgroundSize : 'cover'};
  background-position: ${props =>
    props.styles && props.styles.backgroundPosition
      ? props.styles.backgroundPosition
      : 'center'};
  background-repeat: ${props =>
    props.styles && props.styles.backgroundRepeat ? props.styles.backgroundRepeat : 'no-repeat'};;
`

const DOMBackgroundImage = styled.div`
  ${BackgroundImageCSS};
`

const GatsbyBackgroundImage = styled(BackgroundImage)`
  ${BackgroundImageCSS};
`

const Media = ({ className, src, style, children }) => {
  if(!src) {
    return ''
  }

  if(!src.localFile) {
    return (
      <DOMBackgroundImage
        className={className}
        style={{
          backgroundImage: `url(${src.url})`,
        }}
        styles={style}
        loading="eager"
        fadeIn={false}
      >
        {children}
      </DOMBackgroundImage>
    )
  }
    
  return (
    <GatsbyBackgroundImage 
      className={className} 
      fluid={src.localFile.childImageSharp.fluid} 
      styles={style}
      loading="eager"
      fadeIn={false}
    >
      {children}
    </GatsbyBackgroundImage>
  )
}

export default Media
