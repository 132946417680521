/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'

const StyledBox = styled.div`
  ${props => props.custom ?  css`
    background-color: ${props.theme.color.grey};
  ` : css `
    background-color: ${props.theme.color.secondary};
  `}
  
  ${props => props.opacity &&  css`
    background-color: rgba(70, 75, 62, .85);
  `}

  ${props => props.hover && css`
    transition: 0.25s;
    &:hover {
      transform: scale(1.05);
    }
  `}

  color: ${props => props.theme.color.text.light};
`

const Box = ({ hover, className, children, opacity, custom}) => (
  <StyledBox hover={hover} className={`${className} p-4 `} opacity={opacity} custom={custom}>
    {children}
  </StyledBox>
)

export default Box