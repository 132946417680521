import { navigate } from 'gatsby'

export const isBrowser = () => typeof window !== 'undefined'

export const getLanguage = () =>
    isBrowser() && window.location.href.includes('/en/')
        ? 'en_US'
        : 'nl_NL'

export const setLanguage = node =>
    navigate(node.href)
