import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Images
import arrowRight from 'img/arrow-right.svg'

import CustomLink from 'components/shared/CustomLink'
import ParseContent from 'components/shared/ParseContent'
import { isBrowser } from 'services/language'

import Icon from './Icon'

const Submenu = styled(motion.div)`
  width: 200px;
  position: absolute;
  left: 0;
  display: none;
  top: 20px;
  z-index: 2;
  padding-top: 28px;

  @media screen and (max-width: 991px) {
    padding-top: 0;
    top: 0;
  }

  .wrap {
    background: #fff;

    a[aria-current] {
      color: ${(props) => props.theme.color.main};
      display: flex;
      align-items: center;

      &::before {
        content: '';
        display: block;
        background-image: url(${arrowRight});
        background-size: contain;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    display: block;
  }

  a {
    text-transform: unset;
    display: block;
    color: ${(props) => props.theme.color.text.main};
  }

  @media (max-width: 991.98px) {
    position: relative;
    width: 100%;

    a:first-child {
      margin-top: 0 !important;
    }

    a {
      font-size: 16px !important;
      padding-left: 20px;
    }
  }
`

const Item = styled.li`
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  @media (max-width: 991.98px) {
    overflow: hidden;
  }

  &:hover {
    ${Submenu} {
      display: block;
    }
  }

  & > a {
    color: ${(props) => props.theme.color.text.main};
    font-size: ${(props) => props.theme.font.size.sm};

    &[aria-current] {
      font-weight: bold;
    }

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 1199px) {
      font-size: ${(props) => props.theme.font.size.s};
    }
  }
`

const NavItem = ({ items, childItems }) => {
  const [isHovered, setHovered] = useState(false)
  const isMobile = isBrowser() && window.innerWidth < 992

  return (
    items.parentId === null && (
      <Item
        onMouseEnter={() => !isMobile && setHovered(true)}
        onMouseLeave={() => !isMobile && setHovered(false)}
        className="mr-5"
      >
        <CustomLink to={items.path}>{items.label}</CustomLink>
        {items.parentId === null && items.childItems.nodes.length !== 0 && (
          <span
            onKeyDown={(e) => e.keyCode === 13 && setHovered(!isHovered)}
            tabIndex="0"
            className="outline-none"
            role="button"
            aria-label={isHovered ? 'Close submenu' : 'Open submenu'}
            onClick={() => setHovered(!isHovered)}
          >
            <Icon className="ml-1" icon="chevron-down" size="20px" />
          </span>
        )}

        {childItems.nodes.length !== false && (
          <Submenu
            animate={isHovered ? 'open' : 'collapsed'}
            exit="collapsed"
            variants={{
              open: { display: 'flex' },
              collapsed: { display: 'none' },
            }}
            initial={false}
          >
            <div className="wrap pb-1 pb-lg-2 py-1">
              {childItems.nodes.map((item, index) => (
                <CustomLink
                  className="my-lg-3 px-lg-3 text-shadow"
                  to={item.path}
                  key={index}
                >
                  <ParseContent content={item.label} />
                </CustomLink>
              ))}
            </div>
          </Submenu>
        )}
      </Item>
    )
  )
}

export default NavItem
