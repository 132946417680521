/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  display: inline-block;
  transition: 0.25s;

  ${props =>
    props.white
      ? css`
          background-color: ${props.theme.color.light};
        `
      : css`
          background-color: ${props.theme.color.main};
        `}

  & > a, & > button {
    ${props =>
      props.white
        ? css`
            color: ${props.theme.color.text.contrast};
          `
        : css`
            color: ${props.theme.color.text.light};
          `}

    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
    min-width: 154px;
    text-align: center;
    text-transform: uppercase;
    font-weight: ${props => props.theme.font.weight.m};
  }

  &:hover {
    transform: scale(1.05);
  }
`

const ButtonDefault = ({
  isAnchor,
  isCustom,
  to,
  children,
  className,
  white,
}) => (
  <StyledButton className={className} white={white}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault
