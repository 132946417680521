/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled  from 'styled-components'
import Image from 'components/elements/Image';
import Plaatjie from '@ubo/plaatjie'

import R from 'img/R.svg';

const StyledR = styled.img`
  position:absolute;
  top: 90%;
  left: 0;
`

const Endless = styled.div`
  &::after {
    content: "";
    display:block;
    width: 150%;
    height: 100%;
    background-color: ${(props) => props.theme.color.secondary};
    position: absolute;
    left: 100%;
    top: 0;
  }

  h1 {
    font-size: ${props => props.theme.font.size.xxl};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xl};
    }

    @media screen and (max-width: 576px) {
      font-size: ${props => props.theme.font.size.l};
    }
  }
`

const StyledHero = styled.section`
  @media screen and (max-width: 991px) {
    top: 65px;
  }
`

const StyledImage = styled(Plaatjie)`
  min-height: 392px;

  @media screen and (max-width: 991px) {
    min-height: unset;
    height: 250px;
  }

  @media screen and (max-width: 576px) {
    height: 150px;
  }
`

const Hero = ({ src, title }) => (
  <StyledHero className="position-relative mb-5">
    <StyledImage image={src} className="mb-3" alt="" />
    <div className="container">
      <Endless className="color-background-secondary p-3 mt-n5 position-relative">
        <h1 className="line-height-none mb-0 text-white ml-4">{title}</h1>
      </Endless>
    </div>

    <StyledR src={R} alt="" />
  </StyledHero>
)

export default Hero
