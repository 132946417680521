import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  width: 200px;
  height: 50px;
  background: ${(props) => props.theme.color.main};
  top: 15vh;
  right: 0;
  z-index: 20;
  transform: rotate(-90deg);
  transform-origin: 88% 50%;

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const FixedSideButton = () => {
  const data = useStaticQuery(graphql`
    query {
      wpComponent(databaseId: {eq: 394}) {
        componentButton {
          link {
            url
            title
          }
          fieldGroupName
        }
      }
    }
  `)
  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center h-100">
        <Link className="text-white text-uppercase" to={data.wpComponent.componentButton.link.url}>
          {data.wpComponent.componentButton.link.title}
        </Link>
      </div>
    </Container>
  )
}

export default FixedSideButton
