/* eslint-disable react/no-array-index-key */
import React from 'react'
import Img from 'gatsby-image'

// Third Party
import styled from 'styled-components'
import CustomLink from 'components/shared/CustomLink'

const StyledLogo = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 2000px;
    right: -2000px;
    top: 0;
    background-color: ${(props) => props.theme.color.light};
  }
`

const StyledCustomLink = styled.a`
  width: auto;
  min-width: 74px;
  height: 40px;
`

const StyledImg = styled(Img)`
  width: 100%;
  height: 40px;
`

const Logo = ({ className, content }) => {
  return (
    <StyledLogo
      className={`d-flex align-items-center color-background-light ${
        className ? `${className}` : ``
      }`}
    >
      {content.map((item, index) => (
        <StyledCustomLink
          key={index}
          href={item.link}
          target="_blank"
          className="px-2"
        >
          <StyledImg
            className="mx-1"
            fluid={item.image.localFile.childImageSharp.fluid}
            alt=""
          />
        </StyledCustomLink>
      ))}
    </StyledLogo>
  )
}

export default Logo
