import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    main: '#005F9A',
    contrast: '#009749',
    secondary: '#464B3E',
    alt: '#BFC5BB',
    grey: '#686D5D',
    lightGrey: '#DBDDD7',
    light: '#FFFFFF',
    whiteGrey: '#F4F4F4',

    text: {
      main: '#000000',
      contrast: '#005F9A',
      light: '#FFFFFF',
      grey: '#686D5D',
      whiteGrey: '#F4F4F4',
      alt: '#BFC5BB'
    },
  },

  font: {
    family: {
      main: 'museo-sans, serif',
      secondary: 'Verdana, Arial',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '18px',
      ml: '22px',
      l: '24px',
      xl: '32px',
      xxl: '36px',
      xxxl: '70px',
    },

    weight: {
      s: 300,
      m: 500,
      l: 700,
      xl: 900,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.font.family.main} !important;
    font-size: ${props => props.theme.font.size.sm} !important;
    font-weight: ${props => props.theme.font.weight.s} !important;
    color: ${props => props.theme.color.text.main} !important;
  }
  
  .color {
    &-background {
      &-main { background-color: ${(props) => props.theme.color.main} }
      &-contrast { background-color: ${(props) => props.theme.color.contrast} }
      &-secondary { background-color: ${(props) => props.theme.color.secondary} }
      &-alt { background-color: ${(props) => props.theme.color.alt} }
      &-grey { background-color: ${(props) => props.theme.color.grey} }
      &-lightGrey { background-color: ${(props) => props.theme.color.lightGrey} }
      &-light { background-color: ${(props) => props.theme.color.light} }
    }
    
    &-text {
      &-main { color: ${(props) => props.theme.color.text.main} }
      &-contrast { color: ${(props) => props.theme.color.text.contrast} }
      &-light { color: ${props => props.theme.color.text.light} !important; }
    }
  }
  
  .font {
    &-family {
      &-main { font-family: ${(props) => props.theme.font.family.main} }
      &-secondary { font-family: ${(props) => props.theme.font.family.secondary} }
    }
    
    &-size {
      &-xxs { font-size: ${(props) => props.theme.font.size.xxs} }
      &-xs { font-size: ${(props) => props.theme.font.size.xs} }
      &-s { font-size: ${(props) => props.theme.font.size.s} }
      &-sm { font-size: ${(props) => props.theme.font.size.sm} }
      &-m { font-size: ${(props) => props.theme.font.size.m} }
      &-xm { font-size: ${(props) => props.theme.font.size.xm} }
      &-ml { font-size: ${(props) => props.theme.font.size.ml} }
      &-l { font-size: ${(props) => props.theme.font.size.l} }
      &-xl { font-size: ${(props) => props.theme.font.size.xl} }
      &-xxl { font-size: ${(props) => props.theme.font.size.xxl} }
      &-xxxl { font-size: ${(props) => props.theme.font.size.xxxl} }
    }
    
    &-weight {
      &-xs { font-weight: ${(props) => props.theme.font.weight.xs} }
      &-s { font-weight: ${(props) => props.theme.font.weight.s} }
      &-m { font-weight: ${(props) => props.theme.font.weight.m} }
      &-l { font-weight: ${(props) => props.theme.font.weight.l} }
      &-xl { font-weight: ${(props) => props.theme.font.weight.xl} }
    }
  }

  h1 {
    font-family: ${(props) => props.theme.font.family.main} !important;
    font-size: ${(props) => props.theme.font.size.xxl} !important;
    font-weight: ${(props) => props.theme.font.weight.s} !important;
    text-transform: uppercase;
  }

  h2 {
    font-family: ${(props) => props.theme.font.family.main} ;
    font-size: ${(props) => props.theme.font.size.xl};
    font-weight: ${(props) => props.theme.font.weight.s};
    color: ${props => props.theme.color.text.contrast};
    text-transform: uppercase;
  }

  h3 {
    font-family: ${(props) => props.theme.font.family.main} !important;
    font-size: ${(props) => props.theme.font.size.xm} !important;
    font-weight: ${(props) => props.theme.font.weight.l} !important;
    text-transform: uppercase;
  }

  .line-height-none {
    line-height: 1;
  }
  
  .line-height-m {
    line-height: 1.5;
  }

  @media (min-width: 1200px) {
    .gf_left_half, .gf_right_half {
      width: calc(50% - 7.5px);
      display: inline-block;
    }
    
    .gf_left_half {
      margin-right: 7.5px;
    }
    
    .gf_right_half {
      margin-left: 7.5px;
    }
  }

`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme
