import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

import ButtonDefault from 'components/elements/ButtonDefault'
import ImageBackground from 'components/elements/ImageBackground'
import Box from 'components/elements/Box'

const CtaBackground = styled(Plaatjie)`
  width: 100%;
  height: 100%;
  min-height: 386px;
  max-width: 1140px;
  position: absolute;
  
  @media (min-width: 992px){
    right: 0;
  }
`

const Section = styled.section`
  overflow: hidden;
`

const CtaBox = styled(Box)`
  position: relative;

  h1 {
    font-size: ${props => props.theme.font.size.xxl};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xl};
    }
  }
`

const Container = styled.div`
  position: relative;
  min-height: 386px;

  @media screen and (max-width: 1249px) {
    left: 150px;
  }

  @media screen and (max-width: 991px) {
    left: unset;
  }
`

const BeforeFooter = ({ className, image, cta, title }) => {
  return (
    <Section className={`position-relative d-flex justify-content-end ${className ? `${className}` : ``}`}>
      <CtaBackground image={image} alt={title} />
        <Container className="container h-100 d-flex align-items-center">
          <div className="row justify-content-center justify-content-lg-end">
            <CtaBox className="col-10 col-lg-9">
              <h1 className="mb-4">{title}</h1>
              <ButtonDefault white to={cta.url}>{cta.title}</ButtonDefault>
            </CtaBox>
          </div>
        </Container>
    </Section>
  )
}

export default BeforeFooter
