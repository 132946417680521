/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"

// Components
import Select from 'react-select'
import Content from './shared/Content'
import Loading from './shared/Loading'

// Icons

const StyledForm = styled.form``

const ConfirmationMessage = styled(Content)`
  font-size: ${props => props.theme.font.size.m};
  text-align: center;
`

const Label = styled.label`
  display: block;
`

const Mandatory = styled.span`
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.contrast};
  margin-left: 5px;
`

const GravityForm = ({ className, id, defaultValues = {}, vacature }) => {
  const { allGfForm: { edges: gravityData } } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(({ node }) => node.formId === id)[0]

  const [fields, setFields] = useState(defaultValues)
  const [form, setForm] = useState(gfForm)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const tempForm = form

    // add submit button as a field
    if (tempForm.formFields.filter((item) => item.type === "submit").length === 0)  {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: Math.random().toString(36).substring(7),
          type: "submit",
          text: tempForm.button.text
        }
      ]
    }

    setForm({
      ...form,
      ...tempForm
    })
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      if(defaultValues) {
        for (const [key, value] of Object.entries(defaultValues)) {
          formData.append(key, value);
        }
      }

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value);
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)
      } else {
        setStatus('error')
      }
    } catch (error) {
      setStatus('error')
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring
    let {value} = event.target || event.value

    if (event.target) {
      if (event.target.type === "checkbox") {
        value = event.target.checked ? event.target.value : ""
      }

      setFields({
        ...fields,
        [event.target.name]: value
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value
      })
    }
  }

  if (status === "done") {
    return <ConfirmationMessage content={message} />
  }

  if (form.formFields) {
    return (
      <StyledForm id={`form_${gfForm.formId}`} className={className} method="post" onSubmit={handleOnSubmit}>
        {status === "processing" && (
          <Loading />
        )}
        {form.formFields && form.formFields.map((field, key) => {
          if(field.visibility === 'hidden') {
            return null
          }

          if (Array.isArray(field)) {
            return (
              <React.Fragment key={key}>
                {field.map((item, index) => (
                  <FormField vacature={vacature} key={index} field={item} fields={fields} onChange={handleFieldChange} />
                ))}
              </React.Fragment>
            )
          }

          return (
            <React.Fragment key={key}>
              <FormField vacature={vacature} field={field} fields={fields} onChange={handleFieldChange} />
            </React.Fragment>
          )
        })}
      </StyledForm>
    )
  }

  console.error("No gravity forms found with id", id)
  return false;
}

const StyledTextField = styled.div`
  ${props => props.vacature ? css`
    margin-bottom: 30px;

    input {
      background-color: ${props.theme.color.whiteGrey};
    }

    ${Label} {
      color: ${props.theme.color.text.light};
    }
  ` : css`
  `}
`

const TextField = ({ value, onChange, vacature, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledTextField vacature={vacature} className={`form-group ${cssClass !== undefined ? cssClass : ""}`}>
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && !vacature && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      value={value || ''}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextField>
)

const StyledEmailField = styled.div`
  ${props => props.vacature ? css`
    margin-bottom: 30px;

    input {
      background-color: ${props.theme.color.whiteGrey};
    }

    ${Label} {
      color: ${props.theme.color.text.light};
    }
  ` : css`
  `}
`

const EmailField = ({ value, onChange, vacature, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledEmailField vacature={vacature} className={`form-group ${cssClass !== undefined ? cssClass : ""}`}>
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && !vacature && (<Mandatory>*</Mandatory>)}
    </Label>
    )}
    <input
      value={value || ''}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledEmailField>
)

const StyledPhoneField = styled.div`
  ${props => props.vacature ? css`
    margin-bottom: 30px;

    input {
      background-color: ${props.theme.color.whiteGrey};
    }

    ${Label} {
      color: ${props.theme.color.text.light};
    }
  ` : css`
  `}
`

const PhoneField = ({ value, onChange, vacature, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledPhoneField vacature={vacature} className={`form-group ${cssClass !== undefined ? cssClass : ""}`}>
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && !vacature && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledPhoneField>
)

const StyledTextAreaField = styled.div`
  ${props => props.vacature ? css`
      textarea {
        background-color: ${props.theme.color.whiteGrey};
      }

      ${Label} {
        color: ${props.theme.color.text.light};
      }
  ` : css`
  `}
`

const TextAreaField = ({ value, onChange, vacature, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledTextAreaField vacature={vacature} className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <textarea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      rows="6"
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextAreaField>
)

const CheckboxField = ({ value, onChange, field: { id, type, cssClass, choices } }) => {
  const list = JSON.parse(choices);

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label htmlFor={`input_${id}_${key + 1}`} className="checkbox-content">{checkbox.text}</label>
        </div>
      ))}
    </div>
  )
}

const StyledSelect = styled.div`

& .react-select__control {
  background-color: #d8d8d8;
}

`

// Select or Dropdown
const SelectField = ({ field, onChange, value }) => {

  // Populate a options array
  const options = []
  JSON.parse(field.choices).forEach(choice => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`
    })
  })

  // Custom Select component
  const MySelect = props => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <StyledSelect>
      <MySelect
        options={options}
        onChange={onChange}
        value={options.filter(option => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
      />
    </StyledSelect>
  )
}

const StyledSubmitButton = styled.div`
  button {
    padding: 10px 20px;
    transition: 0.25s;
  }

  &:hover {
    button {
      transform: scale(1.05);
    }
  }

  ${props => props.vacature ? css`
    button {
      background-color: ${props.theme.color.light};
    }
    margin-top: 40px;
  ` : css`
    float: right;
    button {
      background-color: ${props.theme.color.main};
    }
  `}

  & button {
    ${props => props.vacature ? css`
      color: ${props.theme.color.text.contrast};
      font-weight: ${props.theme.font.weight.m};
    ` : css`
      color: ${props.theme.color.text.light};
      font-weight: ${props.theme.font.weight.m};
    `}
    
    text-transform: uppercase;
  }
`

const SubmitButton = ({ field, vacature }) => (
  <div className={`${vacature && `d-flex justify-content-center`}`}>
    <StyledSubmitButton vacature={vacature} rounded isCustomChildren>
      <button type="submit">
        {field.text}
      </button>
    </StyledSubmitButton>
  </div>
)

const StyledFileInput = styled.div`
  ${Label} {
    display: none;
  }

  .show-file {
    color: #FFFFFF !important;
  }

  input {
    padding: 10px 0px;
    background-color: ${props => props.theme.color.grey};
    color: transparent;
    width: 100%;

    &::-webkit-file-upload-button {
      visibility: hidden;
      width: 150px;
    }

    &::before {
      content: 'UPLOAD CV';
      display: inline-block;
      border: 1px solid #ffffff;
      padding: 5px 30px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      color: ${props => props.theme.color.text.light};
    }

    &:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
      color: ${props => props.theme.color.text.main};
    }
  }
`

const FileField = ({ value, onChange, field: { id, type, label, allowedExtensions, cssClass } }) => {
  function handleChange(file) {
      onChange({
        target: {
          name: `input_${id}`,
          value: file
        }
      })

      document.getElementById(`input_${id}`).classList.add('show-file')
  }

  function handleFileUpload(e) {
    const { target: { files } } = e;

    for (let i = 0; i < files.length; i += 1) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e) {
    e.preventDefault();
    
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        handleChange(e.dataTransfer.files[i]);
      }
    }
  }

  return (
    <StyledFileInput className="form-upload" onDrop={handleFileDrop} onDragOver={e => e.preventDefault()}>
      <Label htmlFor={`input_${id}`}>
        {value ? (
          value.name
        ) : (
          <>{label}</>
        )}
      </Label>
      <input
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
        name={`input_${id}`}
        placeholder=" "
        accept={allowedExtensions}
        title=" "
      />
    </StyledFileInput>
  )
}

export const FormField = ({ field, fields, onChange, vacature }) => (
  <>
    {field.type === "text" && <TextField vacature={vacature} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "email" && <EmailField vacature={vacature} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "phone" && <PhoneField vacature={vacature} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "textarea" && <TextAreaField vacature={vacature} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "checkbox" && <CheckboxField vacature={vacature} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "select" && <SelectField vacature={vacature} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "fileupload" && <FileField vacature={vacature} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "submit" && <SubmitButton vacature={vacature} field={field} />}
  </>
)

export default GravityForm


