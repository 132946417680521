import React from 'react'
import styled, { css } from 'styled-components'
import Image from 'gatsby-image'

const ImageCSS = css`
  
`

const DOMImage = styled.img`
  ${ImageCSS};
`

const GatsbyImage = styled(Image)`
  ${ImageCSS};
`

const Media = ({ className, src, style, alt }) => {
  if(!src) {
    return ''
  }

  const regular = !src.localFile || (src.url && src.url.localFile && src.url.localFile.publicURL) || (src.localFile && src.localFile.publicURL)

  if(regular) {
    let url = ''

    if(src.url && src.url.localFile && src.url.localFile.publicURL) {
      url = src.url.localFile.publicURL
    } else if(src.localFile && src.localFile.publicURL) {
      url = src.localFile.publicURL
    } else {
      url = src.url
    }

    return (
      <DOMImage
        className={className}
        src={url}
        style={style}
        alt={alt}
        loading="eager"
        fadeIn={false}
      />
    )
  }

  return (
    <GatsbyImage 
      className={className}
      fluid={src.localFile.childImageSharp.fluid} 
      style={style}
      loading="eager"
      fadeIn={false}
    />
  )
}

export default Media
