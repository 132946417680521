/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from 'components/shared/Footer'
import Header from 'components/shared/Header'
import FixedSideButton from 'components/elements/FixedSideButton'
//import MovingNotice from '../elements/MovingNotice'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'styles/all.css'

const Layout = ({ children, withButton = true }) => (
  <Theme>
    <Header />
    <>{children}</>
    <Footer />

    {/* {withButton && <MovingNotice />} */}
    {withButton && <FixedSideButton />}
  </Theme>
)

export default Layout
