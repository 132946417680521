import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import phoneIcon from 'img/phone-icon.svg'
import mailIcon from 'img/mail-icon.svg'

// Third Party
import styled, { css } from 'styled-components'

const A = styled.a`

  ${props => props.custom ? css`
    color: ${props.theme.color.text.light};

    &:hover {
      color: ${props.theme.color.text.alt};
    }
  ` : css`
    color: ${props.theme.color.text.main};

    &:hover {
      color: ${props.theme.color.text.alt};
    }
  `}
  
`

const ContactDetails = ({ custom }) => {
  const { details } = useStaticQuery(graphql`
    {
      details: wpComponent(databaseId: { eq: 95 }) {
        gegevens {
          email
          phone
          streetname
          housenumber
          zip
          city
          fieldGroupName
        }
      }
    }
  `)

  return (
    <div className="row">
      <div className={`${custom ? 'col-lg-12' : 'col-lg-4'}`}>
        <p className={`${custom ? 'mb-0' : 'mb-3'}`}>
          <span>{details.gegevens.streetname}</span> 
          <span>{details.gegevens.housenumber}</span>
        </p>
        <p>
          <span>{details.gegevens.zip}</span> 
          <span>{details.gegevens.city}</span>
        </p>
      </div>
      <div className={`${custom ? 'col-lg-12' : 'col-lg-8'}`}>
        <A custom={custom} href={`tel:${details.gegevens.phone}`} className={`d-flex align-item-center ${custom ? 'mb-0' : 'mb-3'}`}>
          {!custom && (
            <img src={phoneIcon} className="mr-3" alt="" />
          )}
          {details.gegevens.phone}
        </A>
        <A custom={custom} href={`mailto:${details.gegevens.email}`} className="d-flex align-item-center">
          {!custom && (
          <img src={mailIcon} className="mr-3" alt="" />
          )}
          {details.gegevens.email}
        </A>
      </div>
    </div>
  )
}

export default ContactDetails
