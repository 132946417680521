import React from 'react'

const Icon = ({ icon, size = '24px', className }) => {
  if (icon === 'x') {
    return <XIcon size={size} className={className} />
  }

  if (icon === 'menu') {
    return <MenuIcon size={size} className={className} />
  }

  if (icon === 'chevron-down') {
    return <ChevronDownIcon size={size} className={className} />
  }

  return <div />
}

export const XIcon = ({ size, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className={`feather feather-x ${className}`}
      viewBox="0 0 24 24"
    >
      <path d="M18 6L6 18" />
      <path d="M6 6L18 18" />
    </svg>
  )
}

export const MenuIcon = ({ size, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className={`feather feather-menu ${className}`}
      viewBox="0 0 24 24"
    >
      <path d="M3 12L21 12" />
      <path d="M3 6L21 6" />
      <path d="M3 18L21 18" />
    </svg>
  )
}

export const ChevronDownIcon = ({ size, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className={`feather feather-chevron-down ${className}`}
      viewBox="0 0 24 24"
    >
      <path d="M6 9L12 15 18 9" />
    </svg>
  )
}

export default Icon
