/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import CustomLink from 'components/shared/CustomLink'

const ButtonShell = ({ isAnchor, to, children, className, ...rest }) => (
  !isAnchor ? (
    <CustomLink {...rest} to={to} className={className}>{children}</CustomLink>
  ) : (
    <a {...rest} href={to} className={className}>{children}</a>
  )
)

export default ButtonShell
