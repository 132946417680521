/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Images
import chevronDown from 'img/chevron_down.svg'
import dutch from 'img/dutch.svg'
import english from 'img/english.svg'

// Third Party
import {
  size as _size,
  concat as _concat,
  find as _find,
  each as _each,
  replace as _replace
} from 'lodash'
import styled from 'styled-components'

// Language
import { getLanguage, setLanguage } from 'services/language'

const Language = styled.div``

const LanguageBox = styled.div`
  display: none;
  position: absolute;
  box-shadow: 0px 0px 30px -5px rgba(0,0,0,0.75);
  right: -50px;
  top: 20px;
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 10;

  @media screen and (max-width: 991px) {
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
  }

  &:hover {
    display: flex;
    justify-content: flex-start;
  }
`

const Button = styled.button`
  position: relative;

  &:hover {
    ${LanguageBox} {
      display: flex;
    }
  }
`

const LanguageSwitch = ({ className }) => {
  const posts = { edges: [] }

  const {
    pages,
    // posts,
    site: { pathPrefix },
  } = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
      pages: allWpPage {
        edges {
          node {
            href: uri
            locale {
              id
              locale
            }
            translations {
              locale
              href
              id
            }
          }
        }
      }
    }
  `)

  const language = getLanguage()

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  if (pathPrefix) {
    pathname = _replace(pathname, pathPrefix, '')
  }

  const allPages = _concat(posts.edges, pages.edges)
  let toPage = null

  _each(allPages, ({ node }) => {
    if (node.href === pathname) {
      // eslint-disable-next-line prefer-destructuring
      toPage = node.translations[0]
    }

    if (_size(_find(node.translations, ({ href }) => href === pathname)) > 0) {
      toPage = node
    }
  })

  if (!toPage) {
    toPage = language === 'nl_NL' ? { href: '/en/' } : { href: '/' }
  } else if (toPage.href === '/en/home/') {
    toPage = language === 'nl_NL' ? { href: '/en/' } : { href: '/'}
  }

  const switchLanguage = () => {
    setLanguage(toPage)
  }

  return (
    <Button
      type="button"
      className={`language-switch ${className ? `${className}` : ``}`}
    >
      {language === 'nl_NL' ? 
      (
        <Language>
          Nederlands
          <img className="ml-2" src={chevronDown} alt="" />
        </Language> 
      ) : (
        <Language>
          English
          <img className="ml-2" src={chevronDown} alt="" />
        </Language>
    )}

      <LanguageBox>
        {language === 'nl_NL' ? (
          <Language onClick={() => {switchLanguage(toPage)}}>
            <img className="mr-2" src={english} alt="" />
            English
          </Language>
        ) : (
          <Language onClick={() => {switchLanguage(toPage)}}>
            <img className="mr-2" src={dutch} alt="" />
            Nederlands
          </Language>
        )}
      </LanguageBox>
    </Button>
  )
}

export default LanguageSwitch
