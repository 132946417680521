/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Helpers
import { getLanguage } from 'services/language'

// Images
import greyChevronRight from 'img/grey-chevron-right.svg'
import linkedin from 'img/linkedin.svg'
import facebook from 'img/facebook.svg'
import youtube from 'img/youtube.svg'
import instagram from 'img/instagram.svg'

// Components
import ContactDetails from 'components/shared/ContactDetails'
import MenuItems from 'components/elements/MenuItems'
import CustomLink from 'components/shared/CustomLink'
import Logo from 'components/footer/Logo'
import GravityForm from 'components/GravityForm'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.text.light};
`
const TitleFooter = styled.h4`
  font-size: ${(props) => props.theme.font.size.sm};
  font-weight: ${(props) => props.theme.font.weight.l};
  text-transform: uppercase;
`

const VacatureTitle = styled.p`
  color: ${(props) => props.theme.color.text.light};
`

const VacatureLink = styled(CustomLink)`
  p {
    color: ${(props) => props.theme.color.text.alt};
  }

  &:hover {
    p {
      color: ${(props) => props.theme.color.text.alt};
    }
  }
`

const StyledCustomLink = styled(CustomLink)`
  p {
    color: ${(props) => props.theme.color.text.light};
  }

  &:hover {
    p {
      color: ${(props) => props.theme.color.text.alt};
    }
  }
`

const Footer = () => {
  const language = getLanguage()
  const { footerNL, footerEN } = useStaticQuery(graphql`
    {
      footerNL: wpComponent(databaseId: { eq: 207 }) {
        ...FooterFrag
      }

      footerEN: wpComponent(databaseId: { eq: 689 }) {
        ...FooterFrag
      }
    }
  `)

  const footer = language === 'nl_NL' ? footerNL : footerEN

  return (
    <StyledFooter>
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-3 col-md-6 pb-4 pb-lg-0">
            <TitleFooter className="mb-4">
              {footer.footer.contact.title}
            </TitleFooter>
            <ContactDetails custom />
            <div className="d-flex">
              <StyledCustomLink
                className="pt-4 d-none d-lg-flex mr-3"
                external
                newPage
                to={footer.footer.bottom.linkedin}
              >
                <img src={linkedin} alt="" />
              </StyledCustomLink>
              <StyledCustomLink
                className="pt-4 d-none d-lg-flex mr-3"
                external
                newPage
                to={footer.footer.bottom.facebook}
              >
                <img src={facebook} width="20" alt="" />
              </StyledCustomLink>
              <StyledCustomLink
                className="pt-4 d-none d-lg-flex mr-3"
                external
                newPage
                to={footer.footer.bottom.instagram}
              >
                <img src={instagram} width="20" alt="" />
              </StyledCustomLink>
              <StyledCustomLink
                className="pt-4 d-none d-lg-flex"
                external
                newPage
                to={footer.footer.bottom.youtube}
              >
                <img src={youtube} width="30" alt="" />
              </StyledCustomLink>
            </div>
            <div className="d-lg-flex flex-wrap d-none pt-4">
              <StyledCustomLink
                className="mr-4"
                to={footer.footer.bottom.algemeneVoorwaarden.url}
              >
                <p className="mb-1">
                  {footer.footer.bottom.algemeneVoorwaarden.title}
                </p>
              </StyledCustomLink>
              <StyledCustomLink to={footer.footer.bottom.privacyPolicy.url}>
                <p>{footer.footer.bottom.privacyPolicy.title}</p>
              </StyledCustomLink>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <TitleFooter className="mb-4">
              {footer.footer.menu.title}
            </TitleFooter>
            <MenuItems items={footer.footer.menu.items} />
          </div>
          <div className="col-lg-4 col-md-6">
            <TitleFooter className="mb-4">
              {language === 'nl_NL' ? (
                <span>Inschrijven Nieuwsbrief</span>
              ) : (
                <span>Subscribe to our newsletter</span>
              )}
            </TitleFooter>
            {language === 'nl_NL' ? (
              <p>
                Schrijf u in voor onze nieuwsbrief en blijf zo op de hoogte van
                onze laatste projecten en vacatures.
              </p>
            ) : (
              <p>
                Sign up for our newsletter and stay informed about our latest
                projects and vacancies.
              </p>
            )}
            <GravityForm id={5} />
          </div>
          <div className="col-lg-3 col-md-6">
            <TitleFooter className="mb-4">
              {footer.footer.job.title}
            </TitleFooter>
            <VacatureTitle className="mb-1">
              {footer.footer.job.link.title}
            </VacatureTitle>
            <VacatureLink
              to={footer.footer.job.link.url}
              className="d-flex align-items-center"
            >
              <img className="mr-2" src={greyChevronRight} alt="" />
              <p className="mb-0">
                {language === 'nl_NL' ? `Bekijk vacature` : `View vacancy`}
              </p>
            </VacatureLink>
            <Logo className="mt-5 pl-2 py-3" content={footer.footer.logo} />
            <div className="d-flex">
              <StyledCustomLink
                className="pt-4 d-flex d-lg-none mr-3"
                external
                newPage
                to={footer.footer.bottom.linkedin}
              >
                <img src={linkedin} alt="" />
              </StyledCustomLink>
              <StyledCustomLink
                className="pt-4 d-flex d-lg-none"
                external
                newPage
                to={footer.footer.bottom.facebook}
              >
                <img src={facebook} width="20" alt="" />
              </StyledCustomLink>
            </div>
            <div className="d-lg-none d-flex pt-4">
              <StyledCustomLink
                className="mr-4"
                to={footer.footer.bottom.algemeneVoorwaarden.url}
              >
                <p>{footer.footer.bottom.algemeneVoorwaarden.title}</p>
              </StyledCustomLink>
              <StyledCustomLink to={footer.footer.bottom.privacyPolicy.url}>
                <p>{footer.footer.bottom.privacyPolicy.title}</p>
              </StyledCustomLink>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
