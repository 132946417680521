/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
// import { CSSTransition } from 'react-transition-group'

// Helpers
import { getLanguage } from 'services/language'

// Images
import imgLogo from 'img/logo.svg'
import mail from 'img/mail.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import LanguageSwitch from 'components/LanguageSwitch'
import { indexOf } from 'lodash'
import Icon from './Icon'
import NavItem from './NavItem'

const TopHeader = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: relative;
  padding: 18px 0;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`

const StyledHeader = styled.nav`
  background-color: ${(props) => props.theme.color.alt};
  position: relative;
  padding: 26px 0;

  @media screen and (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 30;
    padding: 0;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Brand = styled(CustomLink)`
  & > img {
    height: auto;
    width: 160px;

    @media screen and (max-width: 991px) {
      width: 120px;
      margin: 10px 0;
    }
  }
`

const NavButton = styled.button`
  /* border: 1px solid ${(props) => props.theme.color.text.secondary}; */
  position: absolute;
  top: 0px;
  right: 5px;
  width: 60px;
  height: 40px;
  padding: 0 10px;
`

const EmailLink = styled.a`
  &:hover {
    color: ${(props) => props.theme.color.text.contrast};
    text-decoration: underline;
  }
`

const Header = () => {
  const language = getLanguage()

  const {
    headerNL,
    headerEN,
    details: {
      gegevens: { email },
    },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      headerNL: wpMenu(databaseId: { eq: 2 }) {
        ...HeaderFrag
      }

      headerEN: wpMenu(databaseId: { eq: 12 }) {
        ...HeaderFrag
      }

      details: wpComponent(databaseId: { eq: 95 }) {
        title
        gegevens {
          email
        }
      }
    }
  `)

  const headerContent = language === 'nl_NL' ? headerNL : headerEN

  const { menuItems } = headerContent

  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <>
      <TopHeader>
        <Container className="container d-none d-lg-flex">
          <Brand to={language === 'nl_NL' ? '/' : '/en/'}>
            <img src={imgLogo} alt="" />
          </Brand>
          <div>
            <EmailLink
              className="mr-4 color-text-contrast text-uppercase"
              href={`mailto:${email}`}
            >
              <img className="mr-2" src={mail} alt="" />
              {email}
            </EmailLink>
            <LanguageSwitch />
          </div>
        </Container>
      </TopHeader>
      <StyledHeader>
        <Container className="container">
          <NavButton
            type="button"
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="d-block d-lg-none h-100"
            aria-label="Open menu"
          >
            <Icon icon="menu" />
          </NavButton>

          <HeaderMenuMobile
            items={menuItems}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
          <HeaderMenuDesktop items={menuItems} />
          <Brand className="d-block d-lg-none" to="/">
            <img src={imgLogo} alt="" />
          </Brand>
        </Container>
      </StyledHeader>
    </>
  )
}

const StyledHeaderMenuMobile = styled(motion.div)`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  color: #000;
  overflow: hidden;
  display: none;
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 991.98px) {
    display: block;
  }

  ul {
    padding-top: 100px;
    padding-bottom: 40px;
    list-style: none;
    padding-left: 35px;
    padding-right: 35px;
    display: flex;
    flex-direction: column;

    li {
      padding-bottom: 10px;
      align-self: start;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;

      svg {
        color: #000 !important;
        width: 24px;
        height: 24px;
      }

      a {
        font-size: 20px;
        color: #000;
        text-shadow: unset;
        background: transparent;
        position: relative;
      }

      &.contact {
        background: transparent;
        padding: initial;
        text-shadow: unset;
      }
    }
  }
`

const HeaderMenuMobile = ({ items, isCollapsed, setIsCollapsed }) => (
  <StyledHeaderMenuMobile
    animate={{
      height: isCollapsed ? 'auto' : 0,
    }}
    transition={{ duration: 0.2 }}
    initial={false}
  >
    <NavButton
      className="mt-3"
      type="button"
      aria-label="Close menu"
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <Icon icon="x" />
    </NavButton>

    <ul>
      <HeaderMenuItems items={items} />
      <LanguageSwitch className="d-block d-lg-none" />
    </ul>
  </StyledHeaderMenuMobile>
)

const StyledHeaderMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
`

const HeaderMenuDesktop = ({ items }) => (
  <StyledHeaderMenuDesktop className="d-none d-lg-flex">
    <HeaderMenuItems items={items} />
  </StyledHeaderMenuDesktop>
)

const HeaderMenuItems = ({ items }) => {
  return (
    <>
      {items.nodes.map((item, index) => {
        return <NavItem key={index} items={item} childItems={item.childItems} />
      })}
    </>
  )
}

export default Header
